import { Box, Typography } from '@mui/material';
import { captionText, commentSx } from './commentUtils';
import { CurrentUserDetails } from '../../../../../security/types';
import { Comment } from '../../../../demandTypes';

type CommentProps = {
  comment: Comment;
  currentUserDetails: CurrentUserDetails;
};

function CommentBox({ comment, currentUserDetails }: CommentProps) {
  const sx = commentSx(currentUserDetails, comment);
  return (
    <Box sx={sx.box}>
      <Box sx={sx.text}>{comment.text}</Box>
      <Box>
        <Typography variant="caption" color="grey.A700">
          {captionText(currentUserDetails, comment)}
        </Typography>
      </Box>
    </Box>
  );
}

export default CommentBox;
