import { UserCompanyRestrictionToMarkCommentsAsReadFn } from './commentsHookType';
import { CurrentMinistryUserDetails, CurrentUserDetails, Permission } from '../../../../../security/types';
import { Demand } from '../../../../demandTypes';
import { isMinistryUser } from '../../../../../user/userUtils';

/**
 * Return a boolean indicating whether we should trigger an HTTP
 * request to mark at least one comment as read.
 */
export function shouldMarkCommentsAsRead(
  demand: Demand,
  currentUserDetails: CurrentUserDetails,
  checkCompanyUserRestriction: UserCompanyRestrictionToMarkCommentsAsReadFn,
): boolean {
  if (!currentUserDetails.permissions.includes(Permission.MARK_COMMENT_AS_READ)) {
    return false;
  }
  if (!hasAnyUnreadComments(demand, currentUserDetails)) {
    return false;
  }
  if (isMinistryUser(currentUserDetails)) {
    return sameTerritorialAreaOrNoUserTerritorialArea(demand, currentUserDetails);
  }
  return checkCompanyUserRestriction(currentUserDetails, demand);
}

/**
 * Return a boolean indicating whether there are some unread comments added
 * by a different company.
 *
 * Comments added by the current user's company are not relevant because users
 * cannot mark as read comments of their own company.
 */
function hasAnyUnreadComments(demand: Demand, currentUserDetails: CurrentUserDetails): boolean {
  return demand.comments.some(
    (comment) => comment.status === 'UNREAD' && comment.authorCompany !== currentUserDetails.company,
  );
}

/**
 * Return a boolean indicating whether the ministry user has no territorial area
 * or the same one as the demand.
 */
function sameTerritorialAreaOrNoUserTerritorialArea(
  demand: Demand,
  currentUserDetails: CurrentMinistryUserDetails,
): boolean {
  return (
    !Boolean(currentUserDetails.territorialArea) ||
    demand.ministryTerritorialArea === currentUserDetails.territorialArea
  );
}
