import {
  PmuCandidateType,
  PmuCaseType,
  PmuDemand,
  PmuFullDemand,
  PmuLightCandidate,
  PmuLightDemand,
} from './pmuDemandTypes';
import {
  LightCandidateFormValues,
  PmuDemandFormCommand,
  PmuDemandFormValues,
  PmuFullDemandFormCommandSpecificValues,
  PmuFullDemandFormSpecificValues,
  PmuLightDemandFormCommandSpecificValues,
  PmuLightDemandFormSpecificValues,
} from './pmuDemandFormTypes';
import { isPmuLightDemand, pmuActiveCaseTypes } from './pmuDemandUtils';
import { toNullSafeDayJsFormValue } from '@eogile/agrements-common/src/form/helper/formUtils';
import {
  toAcquirerCommand,
  toAcquirerFormValues,
  toCandidateCommand,
  toCandidateFormValues,
  toOutletAddressCommand,
  toOutletAddressFormValues,
  toPhoneNumbersCommand,
  toPhoneNumbersFormValues,
} from '@eogile/agrements-common/src/demand/demandFormUtils';
import { CurrentPmuApplicationUserDetails } from '../security/types';
import { currentUserAgency } from '../user/pmuUserUtils';
import { AcquirerType } from '@eogile/agrements-common/src/demand/demandTypes';
import { capitalize, trim, uppercase } from '@eogile/agrements-common/src/utils/stringUtils';
import { formatLocalDate } from '@eogile/agrements-common/src/utils/dateUtils';
import { FormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';

export const buildInitialPmuDemandFormValues = (
  demand: PmuDemand | undefined,
  user: CurrentPmuApplicationUserDetails,
): PmuDemandFormValues => {
  return {
    agencyCode: demand?.agency.code ?? currentUserAgency(user)?.code,
    caseType: demand?.caseType,
    referralDemand: demand?.referralDemand,
    nature: demand?.nature,
    expectedOpeningDate: toNullSafeDayJsFormValue(demand?.expectedOpeningDate),
    ministryReference: demand?.ministryReference,
    rootDemand: demand?.rootDemand ?? false,
    integratedDemand: {
      integratedDemand: demand?.integratedDemand.integratedDemand ?? false,
      rootDemandChronoNumber: demand?.integratedDemand.integratedDemand
        ? demand.integratedDemand.rootDemandChronoNumber
        : undefined,
    },
    comment: demand?.comment,
    ...(demand && isPmuLightDemand(demand)
      ? appendInitialLightDemandFormValues(demand)
      : appendInitialFullDemandFormValues(demand)),
  };
};

const appendInitialLightDemandFormValues = (demand: PmuLightDemand): PmuLightDemandFormSpecificValues => {
  const { outlet } = demand;
  return {
    receivedGrantedVerdictInPast12Months: true,
    previousChronoNumber: demand.previousChronoNumber,
    outlet: {
      name: outlet.name,
      address: toOutletAddressFormValues(outlet.address),
    },
    candidates: demand.candidates.map(toLightCandidateFormValues),
  };
};

export function toLightCandidateFormValues(candidate: PmuLightCandidate): LightCandidateFormValues {
  return {
    lastName: candidate.lastName,
    firstNames: candidate.firstNames,
    birthCity: candidate.birthCity,
    birthDate: toNullSafeDayJsFormValue(candidate.birthDate),
  };
}

const appendInitialFullDemandFormValues = (demand: PmuFullDemand | undefined): PmuFullDemandFormSpecificValues => {
  return {
    receivedGrantedVerdictInPast12Months: false,
    outlet: {
      name: demand?.outlet.name,
      code: demand?.outlet.code,
      operatingMode: demand?.outlet.operatingMode,
      acquisitionDate: toNullSafeDayJsFormValue(demand?.outlet.acquisitionDate),
      address: toOutletAddressFormValues(demand?.outlet.address),
      phoneNumbers: toPhoneNumbersFormValues(demand?.outlet.phoneNumbers),
    },
    acquirer: toAcquirerFormValues(demand),
    // force Final-Form to initialize with an empty candidate
    candidates: demand?.candidates ? demand.candidates.map(toCandidateFormValues) : [undefined as any],
  };
};

export const pmuCandidateTypesCompatibleWith = (acquirerType?: AcquirerType): PmuCandidateType[] => {
  const inOwnNameCompatibleTypes = [PmuCandidateType.MALE_CANDIDATE, PmuCandidateType.FEMALE_CANDIDATE];
  if (!acquirerType || acquirerType === AcquirerType.IN_OWN_NAME) {
    return inOwnNameCompatibleTypes;
  } else {
    return Object.values(PmuCandidateType).filter((c) => !inOwnNameCompatibleTypes.includes(c));
  }
};

export const buildPmuDemandFormCommand = (formValues: PmuDemandFormValues): PmuDemandFormCommand => {
  return {
    ministryReference: trim(formValues.ministryReference),
    comment: trim(formValues.comment),
    agencyCode: formValues.agencyCode!,
    caseType: formValues.caseType!,
    referralDemand: formValues.referralDemand,
    nature: formValues.nature,
    expectedOpeningDate: formatLocalDate(formValues.expectedOpeningDate!),
    ...(formValues.receivedGrantedVerdictInPast12Months
      ? appendLightDemandCommandValues(formValues)
      : appendFullDemandCommandValues(formValues)),
    rootDemand: formValues.rootDemand,
    integratedDemand: {
      integratedDemand: formValues.integratedDemand.integratedDemand,
      rootDemandChronoNumber: formValues.integratedDemand.rootDemandChronoNumber,
    },
  };
};

const appendLightDemandCommandValues = (
  formValues: PmuLightDemandFormSpecificValues,
): PmuLightDemandFormCommandSpecificValues => {
  return {
    receivedGrantedVerdictInPast12Months: true,
    previousChronoNumber: trim(formValues.previousChronoNumber),
    outlet: {
      name: trim(uppercase(formValues.outlet.name))!,
      address: toOutletAddressCommand(formValues.outlet.address),
    },
    candidates: formValues.candidates.map((candidate) => ({
      lastName: trim(uppercase(candidate.lastName))!,
      firstNames: trim(capitalize(candidate.firstNames))!,
      birthCity: trim(uppercase(candidate.birthCity))!,
      birthDate: formatLocalDate(candidate.birthDate!),
    })),
  };
};

const appendFullDemandCommandValues = (
  formValues: PmuFullDemandFormSpecificValues,
): PmuFullDemandFormCommandSpecificValues => {
  return {
    receivedGrantedVerdictInPast12Months: false,
    acquirer: toAcquirerCommand(formValues.acquirer),
    outlet: {
      name: trim(uppercase(formValues.outlet.name))!,
      code: trim(formValues.outlet.code),
      operatingMode: formValues.outlet.operatingMode!,
      acquisitionDate: formatLocalDate(formValues.outlet.acquisitionDate!),
      phoneNumbers: toPhoneNumbersCommand(formValues.outlet.phoneNumbers),
      address: toOutletAddressCommand(formValues.outlet.address),
    },
    candidates: formValues.candidates.map(toCandidateCommand),
  };
};

export const computeCaseTypes = (formMode: FormMode | undefined): PmuCaseType[] => {
  if (!formMode || formMode === 'READ') {
    return Object.values(PmuCaseType);
  }
  return [...pmuActiveCaseTypes];
};
