import { useForm } from 'react-final-form';
import { toAcquirerFormValues, toCandidateFormValues } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { useIntegratedDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIntegratedDemandFormHelper';
import { usePmuCaseTypeFormValue } from './usePmuCaseTypeFormValue';
import { isPmuFullRootDemandView, PmuCaseType, PmuRootDemandView } from '../pmuDemandTypes';
import { toLightCandidateFormValues } from '../pmuDemandFormUtils';

export const usePmuIntegratedDemandFormHelper = () => {
  const { value } = usePmuCaseTypeFormValue();
  const isCreationCaseType = value === PmuCaseType.CREATION;
  const { change } = useForm();

  const prefillWithDemand = (demand: PmuRootDemandView) => {
    if (isPmuFullRootDemandView(demand)) {
      change('acquirer', { ...toAcquirerFormValues(demand), eirl: undefined });
      change('candidates', demand.candidates.map(toCandidateFormValues));
    } else {
      change('acquirer', undefined);
      change('candidates', demand.candidates.map(toLightCandidateFormValues));
    }
  };

  return useIntegratedDemandFormHelper({ isCreationCaseType, prefillWithDemand });
};
