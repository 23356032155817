import ApiHelper from '../api/apiHelper';

const findDemandWithGrantedVerdictByChronoNumber = async <T>(chronoNumber: string): Promise<T> => {
  try {
    return await ApiHelper.get(`/api/demands/root`, { chronoNumber });
  } catch (e) {
    throw new Error('Could not find existing demand for this chrono number');
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findDemandWithGrantedVerdictByChronoNumber,
};
