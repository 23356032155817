import {
  Acquirer,
  AcquirerCompanyType,
  AcquirerType,
  Candidate,
  CandidateAddress,
  Demand,
  DemandWithVerdict,
  OutletAddress,
  PhoneNumbers,
} from './demandTypes';
import { activeAcquirerCompanyTypes, demandHasBeenReceivedByMinistry, demandIsInFinalState } from './demandUtils';
import { AuthenticatedCurrentUser } from '../security/types';
import { UserCompany } from '../user/commonTypes';
import {
  AcquirerFormValues,
  AddressFormValues,
  CandidateAddressFormValues,
  CandidateFormValues,
  PhoneNumbersFormValues,
} from './demandFormTypes';
import { toNullSafeDayJsFormValue } from '../form/helper/formUtils';
import { capitalize, trim, uppercase } from '../utils/stringUtils';
import { formatLocalDate } from '../utils/dateUtils';
import { FormMode } from '../form/helper/FormModeProvider';

export const prefillRequestHiddenFieldName = '__ROOT_DEMAND_PREFILL_REQUEST__';

export const isLightDemandForm = ({
  receivedGrantedVerdictInPast12Months,
}: {
  receivedGrantedVerdictInPast12Months: boolean;
}) => Boolean(receivedGrantedVerdictInPast12Months);

export const isVisibleMinistryDataSection = (demand: Demand | undefined): demand is Demand => {
  return Boolean(demand && demandHasBeenReceivedByMinistry(demand));
};

export const isVisibleMinistryVerdictSection = (
  demand: Demand | undefined,
  { userDetails }: AuthenticatedCurrentUser,
): demand is DemandWithVerdict => {
  if (!demand || !demand.verdict?.status) {
    return false;
  }
  return demandIsInFinalState(demand) || userDetails.company === UserCompany.MINISTRY;
};

export const toAcquirerFormValues = (demand: { acquirer: Acquirer } | undefined): AcquirerFormValues => {
  if (demand?.acquirer.type === AcquirerType.COMPANY) {
    return {
      type: AcquirerType.COMPANY,
      companyType: demand.acquirer.companyType,
    };
  } else if (demand?.acquirer.type === AcquirerType.IN_OWN_NAME) {
    return {
      type: AcquirerType.IN_OWN_NAME,
      eirl: demand.acquirer.eirl != null ? (demand.acquirer.eirl.toString() as 'true' | 'false') : undefined,
    };
  }
  return { type: undefined };
};

export function toCandidateFormValues<T>(candidate: Candidate<T>): CandidateFormValues<T> {
  return {
    type: candidate.type,
    civility: candidate.civility,
    lastName: candidate.lastName,
    firstNames: candidate.firstNames,
    birthName: candidate.birthName,
    birthCity: candidate.birthCity,
    birthDate: toNullSafeDayJsFormValue(candidate.birthDate),
    birthDepartmentOrCountry: candidate.birthDepartmentOrCountry,
    address: {
      address1: candidate.address.address1,
      address2: candidate.address.address2,
      zipCode: candidate.address.zipCode,
      city: candidate.address.city,
      country: candidate.address.country,
    },
    phoneNumbers: toPhoneNumbersFormValues(candidate.phoneNumbers),
    email: candidate.email,
  };
}

export const toOutletAddressFormValues = (address: OutletAddress | undefined) => {
  return {
    address1: address?.address1,
    address2: address?.address2,
    zipCode: address?.zipCode,
    city: address?.city,
  };
};

export const toPhoneNumbersFormValues = (phoneNumbers: PhoneNumbers | undefined) => {
  return {
    phone1: phoneNumbers?.phone1,
    phone2: phoneNumbers?.phone2,
  };
};

export const eirlFormValueToBoolean = (eirlFormValue: 'true' | 'false' | undefined): boolean | null => {
  if (eirlFormValue) {
    return eirlFormValue === 'true';
  }
  return null;
};

export const withFieldNamePrefix = (prefix: string) => (subPath: string) => `${prefix}.${subPath}`;

export const toOutletAddressCommand = (address: AddressFormValues): OutletAddress => {
  return {
    address1: trim(uppercase(address.address1))!,
    address2: trim(uppercase(address.address2)),
    zipCode: address.zipCode!,
    city: trim(uppercase(address.city))!,
  };
};

export const toAcquirerCommand = (acquirer: AcquirerFormValues): Acquirer => {
  if (acquirer.type === AcquirerType.IN_OWN_NAME) {
    return { type: AcquirerType.IN_OWN_NAME, eirl: eirlFormValueToBoolean(acquirer.eirl) };
  } else if (acquirer.type === AcquirerType.COMPANY) {
    return { type: AcquirerType.COMPANY, companyType: acquirer.companyType! };
  }
  throw new Error('invalid acquirer state');
};

export const toPhoneNumbersCommand = (phoneNumbers: PhoneNumbersFormValues): PhoneNumbers => ({
  phone1: phoneNumbers.phone1!,
  phone2: phoneNumbers.phone2,
});

export function toCandidateCommand<T>(candidate: CandidateFormValues<T>): Candidate<T> {
  return {
    type: candidate.type!,
    civility: candidate.civility!,
    lastName: trim(uppercase(candidate.lastName))!,
    firstNames: trim(capitalize(candidate.firstNames))!,
    birthCity: trim(uppercase(candidate.birthCity))!,
    birthDate: formatLocalDate(candidate.birthDate!),
    birthName: trim(uppercase(candidate.birthName)),
    birthDepartmentOrCountry: trim(uppercase(candidate.birthDepartmentOrCountry))!,
    email: candidate.email,
    address: toCandidateAddressCommand(candidate.address),
    phoneNumbers: toPhoneNumbersCommand(candidate.phoneNumbers),
  };
}

const toCandidateAddressCommand = (address: CandidateAddressFormValues): CandidateAddress => {
  return {
    address1: trim(uppercase(address.address1))!,
    address2: trim(uppercase(address.address2)),
    zipCode: address.zipCode!,
    city: trim(uppercase(address.city))!,
    country: trim(address.country),
  };
};

export const generalInformationSectionTitle = 'Informations générales sur la demande';
export const outletSectionTitle = 'Point de vente';
export const acquirerSectionTitle = 'Acquéreur';
export const existingAttachmentRemovalWarningText = 'Ce changement supprimera les pièces justificatives';

export const computeCompanyTypes = (formMode: FormMode | undefined): AcquirerCompanyType[] => {
  if (!formMode || formMode === 'READ') {
    return Object.values(AcquirerCompanyType);
  }
  return [...activeAcquirerCompanyTypes];
};
