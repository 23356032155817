import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Badge } from '@mui/material';
import { CurrentUserDetails } from '../../../security/types';
import { SimpleDemandView } from '../../demandListTypes';
import TableCell from '../../../components/table/TableCell';

type DemandCommentsCellProps = {
  currentUserDetails: CurrentUserDetails;
  demandView: SimpleDemandView;
};

function DemandCommentsCell({ currentUserDetails, demandView }: DemandCommentsCellProps) {
  if (demandView.nbComments === 0) {
    return <TableCell />;
  }

  const nb = demandView.nbUnreadComments[currentUserDetails.company];
  const Icon = <ChatBubbleOutlineIcon color="action" fontSize="small" sx={{ verticalAlign: 'middle' }} />;

  return (
    <TableCell>
      {nb > 0 && (
        <Badge badgeContent={nb} color="primary" variant="dot">
          {Icon}
        </Badge>
      )}
      {nb === 0 && Icon}
    </TableCell>
  );
}

export default DemandCommentsCell;
