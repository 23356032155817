import React from 'react';
import { CurrentPmuApplicationUserDetails } from '../../../../security/types';
import DemandCommentsSection from '@eogile/agrements-common/src/demand/components/comments/section/DemandCommentsSection';
import { PmuDemand } from '../../../pmuDemandTypes';
import { checkCompanyUserRestrictionToMarkAsRead } from '../utils/pmuCommentUtils';

type PmuDemandCommentsSectionProps = {
  demand: PmuDemand;
  currentUserDetails: CurrentPmuApplicationUserDetails;
  onDemandRefresh: (demand: PmuDemand) => void;
};

function PmuDemandCommentsSection({ demand, currentUserDetails, onDemandRefresh }: PmuDemandCommentsSectionProps) {
  return (
    <DemandCommentsSection<PmuDemand>
      demand={demand}
      currentUserDetails={currentUserDetails}
      onDemandRefresh={onDemandRefresh}
      checkCompanyUserRestrictionToMarkAsRead={checkCompanyUserRestrictionToMarkAsRead}
    />
  );
}

export default React.memo(PmuDemandCommentsSection);
