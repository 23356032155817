import {
  AttachmentHelper,
  AttachmentPresenceRule,
  AttachmentPresenceRules,
} from '@eogile/agrements-common/src/demand/attachmentTypes';
import { PmuAttachmentType, PmuCaseType, PmuDemand } from './pmuDemandTypes';
import { isPmuFullDemand, isPmuLightDemand } from './pmuDemandUtils';
import { AcquirerType, OperatingMode } from '@eogile/agrements-common/src/demand/demandTypes';
import { getDiffInYearsRelativeToNow } from '@eogile/agrements-common/src/utils/dateUtils';
import dayjs from 'dayjs';
import { attachmentTypeDisplayOrderWeightsFromOrderedList } from '@eogile/agrements-common/src/demand/attachmentUtils';

const conditionsUtil = (demand: PmuDemand) => ({
  isIntegratedDemand: () => demand.integratedDemand.integratedDemand,
  acquirer: (wanted: AcquirerType) => isPmuFullDemand(demand) && demand.acquirer.type === wanted,
  caseType: (wanted: PmuCaseType) => demand.caseType === wanted,
  caseTypeIn: (wanted: PmuCaseType[]) => wanted.includes(demand.caseType),
  operatingMode: (wanted: OperatingMode) => isPmuFullDemand(demand) && demand.outlet.operatingMode === wanted,
  acquisitionDate: (wanted: '-3years' | '+3years') => {
    if (!isPmuFullDemand(demand)) {
      return false;
    }
    const diff = getDiffInYearsRelativeToNow(dayjs(demand.outlet.acquisitionDate));
    return wanted === '+3years' ? diff >= 3 : diff < 3;
  },
});

const avisImpositionOrLivretFamilleCondition: (
  type: PmuAttachmentType.LivretFamille | PmuAttachmentType.AvisImposition | PmuAttachmentType.AttestationDemandeur,
) => AttachmentPresenceRule<PmuDemand> = (_) => (demand: PmuDemand) => {
  const { acquirer, caseTypeIn, isIntegratedDemand } = conditionsUtil(demand);
  if (isIntegratedDemand()) {
    return 'FACULTATIVE';
  }
  if (isPmuLightDemand(demand)) {
    return 'INCOMPATIBLE';
  }
  if (
    acquirer(AcquirerType.IN_OWN_NAME) &&
    caseTypeIn([PmuCaseType.LEGAL_REPRESENTATIVE_CHANGE, PmuCaseType.ASSOCIATE_CHANGE, PmuCaseType.ASSOCIATE_ADDED])
  ) {
    return 'INCOMPATIBLE';
  }
  return 'MANDATORY';
};

const accordPretOrAccordPretBrasseurCondition: (
  type: PmuAttachmentType.AccordPret | PmuAttachmentType.AccordPretBrasseur,
) => AttachmentPresenceRule<PmuDemand> = (type) => (demand: PmuDemand) => {
  const { caseType, operatingMode, acquisitionDate, isIntegratedDemand } = conditionsUtil(demand);
  if (isPmuLightDemand(demand) || isIntegratedDemand()) {
    return 'INCOMPATIBLE';
  }
  if (
    operatingMode(OperatingMode.DIRECT_EXPLOITATION) &&
    ((caseType(PmuCaseType.CREATION) && acquisitionDate('-3years')) || caseType(PmuCaseType.INCUMBENT_CHANGE))
  ) {
    return type === PmuAttachmentType.AccordPret ? 'MANDATORY' : 'FACULTATIVE';
  }
  return 'INCOMPATIBLE';
};

const planCadastralOrAttestationZoneProtegeeCondition: (
  type: PmuAttachmentType.PlanCadastral | PmuAttachmentType.AttestationZoneProtegee,
) => AttachmentPresenceRule<PmuDemand> = (_) => (demand: PmuDemand) => {
  const { caseType, isIntegratedDemand } = conditionsUtil(demand);
  if (isIntegratedDemand()) {
    return 'MANDATORY';
  }
  if (isPmuLightDemand(demand)) {
    return 'INCOMPATIBLE';
  }
  if (caseType(PmuCaseType.CREATION)) {
    return 'MANDATORY';
  }
  return 'INCOMPATIBLE';
};

const pmuAttachmentPresenceRules: AttachmentPresenceRules<PmuDemand, PmuAttachmentType> = {
  [PmuAttachmentType.PieceIdentite]: (demand) => {
    const { acquirer, caseTypeIn, isIntegratedDemand } = conditionsUtil(demand);
    if (isIntegratedDemand()) {
      return 'FACULTATIVE';
    }
    if (isPmuLightDemand(demand)) {
      return 'MANDATORY';
    }
    if (
      acquirer(AcquirerType.IN_OWN_NAME) &&
      caseTypeIn([PmuCaseType.LEGAL_REPRESENTATIVE_CHANGE, PmuCaseType.ASSOCIATE_CHANGE, PmuCaseType.ASSOCIATE_ADDED])
    ) {
      return 'INCOMPATIBLE';
    }
    return 'MANDATORY';
  },
  [PmuAttachmentType.AvisImposition]: avisImpositionOrLivretFamilleCondition(PmuAttachmentType.AvisImposition),
  [PmuAttachmentType.LivretFamille]: avisImpositionOrLivretFamilleCondition(PmuAttachmentType.LivretFamille),
  [PmuAttachmentType.ActeAchat]: (demand) => {
    const { caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (caseType(PmuCaseType.CREATION) && operatingMode(OperatingMode.DIRECT_EXPLOITATION)) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.PromesseVente]: (demand) => {
    const { acquirer, caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      acquirer(AcquirerType.IN_OWN_NAME) &&
      caseType(PmuCaseType.INCUMBENT_CHANGE) &&
      operatingMode(OperatingMode.DIRECT_EXPLOITATION)
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.Bail]: (demand) => {
    const { acquirer, caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      operatingMode(OperatingMode.DIRECT_EXPLOITATION) &&
      (caseType(PmuCaseType.CREATION) || (acquirer(AcquirerType.IN_OWN_NAME) && caseType(PmuCaseType.INCUMBENT_CHANGE)))
    ) {
      return 'FACULTATIVE';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.Adjudication]: (demand) => {
    const { caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      operatingMode(OperatingMode.DIRECT_EXPLOITATION) &&
      (caseType(PmuCaseType.CREATION) || caseType(PmuCaseType.INCUMBENT_CHANGE))
    ) {
      return 'FACULTATIVE';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.ContratLocationGerance]: (demand) => {
    const { caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      operatingMode(OperatingMode.LEASE_MANAGEMENT) &&
      (caseType(PmuCaseType.CREATION) || caseType(PmuCaseType.INCUMBENT_CHANGE))
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.ConventionOccupation]: (demand) => {
    const { caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      operatingMode(OperatingMode.OCCUPATION) &&
      (caseType(PmuCaseType.CREATION) || caseType(PmuCaseType.INCUMBENT_CHANGE))
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.AccordPret]: accordPretOrAccordPretBrasseurCondition(PmuAttachmentType.AccordPret),
  [PmuAttachmentType.AccordPretBrasseur]: accordPretOrAccordPretBrasseurCondition(PmuAttachmentType.AccordPretBrasseur),
  [PmuAttachmentType.JustificationApports]: (demand) => {
    const { acquirer, caseType, caseTypeIn, operatingMode, acquisitionDate, isIntegratedDemand } =
      conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      operatingMode(OperatingMode.DIRECT_EXPLOITATION) &&
      ((caseType(PmuCaseType.CREATION) && acquisitionDate('-3years')) || caseType(PmuCaseType.INCUMBENT_CHANGE))
    ) {
      return 'MANDATORY';
    } else if (
      acquirer(AcquirerType.COMPANY) &&
      caseTypeIn([PmuCaseType.ASSOCIATE_ADDED, PmuCaseType.ASSOCIATE_CHANGE])
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.ExtraitK]: (demand) => {
    const { acquirer, caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      acquirer(AcquirerType.IN_OWN_NAME) &&
      caseType(PmuCaseType.CREATION) &&
      operatingMode(OperatingMode.DIRECT_EXPLOITATION)
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.ExtraitKbis]: (demand) => {
    const { acquirer, caseType, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      acquirer(AcquirerType.COMPANY) &&
      caseType(PmuCaseType.CREATION) &&
      operatingMode(OperatingMode.DIRECT_EXPLOITATION)
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.StatutsSociete]: (demand) => {
    const { acquirer, caseTypeIn, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (acquirer(AcquirerType.COMPANY) && caseTypeIn([PmuCaseType.CREATION, PmuCaseType.INCUMBENT_CHANGE])) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.LiasseFiscale]: () => 'INCOMPATIBLE', // not present anymore
  [PmuAttachmentType.CessionParts]: (demand) => {
    const { acquirer, caseType, caseTypeIn, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      acquirer(AcquirerType.COMPANY) &&
      ((caseType(PmuCaseType.INCUMBENT_CHANGE) && operatingMode(OperatingMode.DIRECT_EXPLOITATION)) ||
        caseTypeIn([PmuCaseType.ASSOCIATE_CHANGE, PmuCaseType.ASSOCIATE_ADDED]))
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.NonChangementOrganesDirection]: (demand) => {
    const { isIntegratedDemand } = conditionsUtil(demand);
    if (isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (isPmuLightDemand(demand)) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.JustificationFondsFinancementLocationGerance]: (demand) => {
    const { caseType, operatingMode, acquisitionDate, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      operatingMode(OperatingMode.LEASE_MANAGEMENT) &&
      ((caseType(PmuCaseType.CREATION) && acquisitionDate('-3years')) || caseType(PmuCaseType.INCUMBENT_CHANGE))
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.AutresPieces]: (demand) => {
    const { acquirer, caseTypeIn, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'FACULTATIVE';
    }
    if (
      acquirer(AcquirerType.IN_OWN_NAME) &&
      caseTypeIn([PmuCaseType.LEGAL_REPRESENTATIVE_CHANGE, PmuCaseType.ASSOCIATE_CHANGE, PmuCaseType.ASSOCIATE_ADDED])
    ) {
      return 'INCOMPATIBLE';
    }
    return 'FACULTATIVE';
  },
  [PmuAttachmentType.PlanCadastral]: planCadastralOrAttestationZoneProtegeeCondition(PmuAttachmentType.PlanCadastral),
  [PmuAttachmentType.AttestationZoneProtegee]: planCadastralOrAttestationZoneProtegeeCondition(
    PmuAttachmentType.AttestationZoneProtegee,
  ),
  [PmuAttachmentType.PlanDeFinancement]: (demand) => {
    const { caseType, acquisitionDate, operatingMode, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      (caseType(PmuCaseType.CREATION) &&
        acquisitionDate('-3years') &&
        (operatingMode(OperatingMode.DIRECT_EXPLOITATION) || operatingMode(OperatingMode.LEASE_MANAGEMENT))) ||
      caseType(PmuCaseType.INCUMBENT_CHANGE)
    ) {
      return 'MANDATORY';
    }
    return 'INCOMPATIBLE';
  },
  [PmuAttachmentType.AttestationDemandeur]: (demand: PmuDemand) => {
    const { acquirer, caseTypeIn, isIntegratedDemand } = conditionsUtil(demand);
    if (isPmuLightDemand(demand) || isIntegratedDemand()) {
      return 'INCOMPATIBLE';
    }
    if (
      acquirer(AcquirerType.IN_OWN_NAME) &&
      caseTypeIn([PmuCaseType.LEGAL_REPRESENTATIVE_CHANGE, PmuCaseType.ASSOCIATE_CHANGE, PmuCaseType.ASSOCIATE_ADDED])
    ) {
      return 'INCOMPATIBLE';
    }
    return 'MANDATORY';
  },
};

const pmuAttachmentTypeLabels: Record<PmuAttachmentType, string> = {
  [PmuAttachmentType.PieceIdentite]: "Pièce d'identité",
  [PmuAttachmentType.AvisImposition]: "Avis d'imposition",
  [PmuAttachmentType.LivretFamille]: 'Livret de famille / Acte de naissance',
  [PmuAttachmentType.ActeAchat]: "Acte d'achat enregistré",
  [PmuAttachmentType.Bail]: 'Cession du droit au bail / bail',
  [PmuAttachmentType.PromesseVente]: 'Promesse de vente enregistrée',
  [PmuAttachmentType.Adjudication]: 'Jugement du tribunal suite à adjudication',
  [PmuAttachmentType.ContratLocationGerance]: 'Contrat de location-gérance',
  [PmuAttachmentType.ConventionOccupation]: "Convention d'occupation",
  [PmuAttachmentType.AccordPret]: 'Accord de prêt',
  [PmuAttachmentType.AccordPretBrasseur]: 'Accord de prêt brasseur',
  [PmuAttachmentType.JustificationApports]: 'Justification des apports',
  [PmuAttachmentType.ExtraitK]: 'Extrait K',
  [PmuAttachmentType.ExtraitKbis]: 'Extrait Kbis',
  [PmuAttachmentType.StatutsSociete]: 'Statuts de la société ou projet',
  [PmuAttachmentType.LiasseFiscale]: 'Liasse fiscale',
  [PmuAttachmentType.CessionParts]: 'Promesse de vente ou de cessions de parts enregistrée',
  [PmuAttachmentType.NonChangementOrganesDirection]: 'Attestation de non changement des organes de direction',
  [PmuAttachmentType.JustificationFondsFinancementLocationGerance]:
    'Justification des fonds destinés au financement de la caution de la location gérance',
  [PmuAttachmentType.AutresPieces]: 'Autres pièces',
  [PmuAttachmentType.PlanCadastral]: 'Plan cadastral',
  [PmuAttachmentType.AttestationZoneProtegee]: 'Attestation zone protégée',
  [PmuAttachmentType.PlanDeFinancement]: 'Plan de financement',
  [PmuAttachmentType.AttestationDemandeur]: 'Attestation demandeur(s)',
};

export const pmuAttachmentHelper: AttachmentHelper<PmuDemand, PmuAttachmentType> = {
  attachmentTypeDisplayOrderWeights: attachmentTypeDisplayOrderWeightsFromOrderedList([
    PmuAttachmentType.PieceIdentite,
    PmuAttachmentType.AvisImposition,
    PmuAttachmentType.LivretFamille,
    PmuAttachmentType.ActeAchat,
    PmuAttachmentType.Bail,
    PmuAttachmentType.PromesseVente,
    PmuAttachmentType.Adjudication,
    PmuAttachmentType.ContratLocationGerance,
    PmuAttachmentType.ConventionOccupation,
    PmuAttachmentType.AccordPret,
    PmuAttachmentType.AccordPretBrasseur,
    PmuAttachmentType.JustificationApports,
    PmuAttachmentType.ExtraitK,
    PmuAttachmentType.ExtraitKbis,
    PmuAttachmentType.StatutsSociete,
    PmuAttachmentType.LiasseFiscale,
    PmuAttachmentType.CessionParts,
    PmuAttachmentType.NonChangementOrganesDirection,
    PmuAttachmentType.JustificationFondsFinancementLocationGerance,

    PmuAttachmentType.PlanCadastral,
    PmuAttachmentType.AttestationZoneProtegee,
    PmuAttachmentType.PlanDeFinancement,
    PmuAttachmentType.AttestationDemandeur,

    PmuAttachmentType.AutresPieces,
  ]),
  attachmentPresenceRules: pmuAttachmentPresenceRules,
  attachmentTypeInfoTooltips: {},
  attachmentTypeLabels: pmuAttachmentTypeLabels,
  attachmentTypesAllowMultiple: [PmuAttachmentType.AutresPieces],
  attachmentTypesForCandidate: [
    PmuAttachmentType.PieceIdentite,
    PmuAttachmentType.AvisImposition,
    PmuAttachmentType.LivretFamille,
  ],
};
