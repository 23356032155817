import React from 'react';
import CommentBox from './internal/CommentBox';
import { Box } from '@mui/material';
import useComments from '../hooks/useComments';
import { UserCompanyRestrictionToMarkCommentsAsReadFn } from '../hooks/internal/commentsHookType';
import { Demand } from '../../../demandTypes';
import { CurrentUserDetails } from '../../../../security/types';
import { SectionCard } from '../../../../components/cards/SectionCard';

type DemandCommentsSectionProps<D extends Demand> = {
  demand: D;
  currentUserDetails: CurrentUserDetails;
  onDemandRefresh: (demand: D) => void;
  checkCompanyUserRestrictionToMarkAsRead: UserCompanyRestrictionToMarkCommentsAsReadFn;
};

function DemandCommentsSection<D extends Demand>({
  demand,
  currentUserDetails,
  onDemandRefresh,
  checkCompanyUserRestrictionToMarkAsRead,
}: DemandCommentsSectionProps<D>) {
  const { comments, ref } = useComments(
    demand,
    currentUserDetails,
    onDemandRefresh,
    checkCompanyUserRestrictionToMarkAsRead,
  );

  return (
    <SectionCard title="Commentaires">
      <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {comments.map((comment, i) => (
          <CommentBox key={i} comment={comment} currentUserDetails={currentUserDetails} />
        ))}
      </Box>
    </SectionCard>
  );
}

export default DemandCommentsSection;
