import { SxProps, Theme } from '@mui/material';
import dayjs from 'dayjs';
import { CurrentUserDetails } from '../../../../../security/types';
import { Comment, isReadComment } from '../../../../demandTypes';

//////////////////////////////////////////
// CSS utilities
//////////////////////////////////////////

type CommentSx = Readonly<{
  box: SxProps<Theme>;
  text: SxProps<Theme>;
}>;

/**
 * Return the SX props to display the comment.
 *
 * If the current user is the author, the comment is aligned on the right
 * with a colored background. Otherwise, it is aligned on the left.
 *
 * @param userDetails The current user.
 * @param comment The comment.
 */
export function commentSx(userDetails: CurrentUserDetails, comment: Comment): CommentSx {
  const isTheUserTheAuthor = isCurrentUserTheAuthor(userDetails, comment);
  return {
    box: boxSxProps(isTheUserTheAuthor),
    text: textSxProps(isTheUserTheAuthor),
  };
}

function boxSxProps(isCurrentUserTheAuthor: boolean): SxProps<Theme> {
  const commonProps: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
  };
  const margin = '20%';
  if (isCurrentUserTheAuthor) {
    return {
      ...commonProps,
      alignSelf: 'flex-end',
      ml: margin, // Always keep a margin on the left
      alignItems: 'flex-end',
    };
  }
  return {
    ...commonProps,
    alignSelf: 'flex-start',
    mr: margin, // Always keep a margin on the right
  };
}

function textSxProps(isCurrentUserTheAuthor: boolean): SxProps<Theme> {
  const commonTextSx: SxProps<Theme> = {
    borderRadius: 3,
    px: 2,
    py: 1,
    whiteSpace: 'pre-line',
  };
  if (isCurrentUserTheAuthor) {
    return (theme) => ({
      ...commonTextSx,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      alignSelf: 'flex-end',
    });
  }
  return (theme) => ({
    ...commonTextSx,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1,
    alignSelf: 'flex-start',
  });
}

//////////////////////////////////////////
// Caption utilities
//////////////////////////////////////////

const COMMENT_DATE_TIME_FORMAT = 'DD/MM/YYYY [à] HH [h] mm';

/**
 * Return the caption to display.
 *
 * @param userDetails The current user.
 * @param comment The comment.
 */
export function captionText(userDetails: CurrentUserDetails, comment: Comment): string {
  return `${authorCaptionSection(userDetails, comment)} ${dateTimeCaptionSection(comment)} - ${statusCaptionSection(comment)}`;
}

function authorCaptionSection(userDetails: CurrentUserDetails, comment: Comment): string {
  if (isCurrentUserTheAuthor(userDetails, comment)) {
    return 'Vous';
  } else {
    return comment.authorLogin;
  }
}

function dateTimeCaptionSection(comment: Comment): string {
  return `le ${dayjs(comment.dateTime).format(COMMENT_DATE_TIME_FORMAT)}`;
}

function statusCaptionSection(comment: Comment): string {
  if (isReadComment(comment)) {
    return `Lu le ${dayjs(comment.readDateTime).format(COMMENT_DATE_TIME_FORMAT)}`;
  }
  return 'Non lu';
}

//////////////////////////////////////////
// Common utilities
//////////////////////////////////////////

/**
 * Return a boolean indicating whether the current user is the author of
 * the given comment.
 *
 * @param userDetails The current user.
 * @param comment The comment.
 */
function isCurrentUserTheAuthor(userDetails: CurrentUserDetails, comment: Comment): boolean {
  return userDetails.login === comment.authorLogin;
}
