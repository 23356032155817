import { PmuVerdictInputCommand, PmuVerdictInputFormValues } from './pmuVerdictInputFormTypes';
import { RejectionReason, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import {
  hasAskedCancellation,
  toStandardCancelledVerdictInputCommand,
  toStandardCancelledVerdictInputFormValues,
  toStandardCompleteCaseVerdictInputCommand,
  toStandardCompleteCaseVerdictInputFormValues,
  toStandardGrantedVerdictInputCommand,
  toStandardGrantedVerdictInputFormValues,
  toStandardRejectedVerdictInputCommand,
  toStandardRejectedVerdictInputFormValues,
} from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';
import { PmuDemand } from '../../../pmuDemandTypes';

export const buildInitialPmuVerdictInputFormValues = (demand: PmuDemand): PmuVerdictInputFormValues => {
  if (hasAskedCancellation(demand)) {
    return { verdictType: VerdictType.CANCELLED };
  }
  switch (demand.verdict?.status) {
    case VerdictType.GRANTED:
      return toStandardGrantedVerdictInputFormValues();
    case VerdictType.COMPLETE_CASE:
      return toStandardCompleteCaseVerdictInputFormValues();
    case VerdictType.CANCELLED:
      return toStandardCancelledVerdictInputFormValues(demand);
    case VerdictType.REJECTED:
      return toStandardRejectedVerdictInputFormValues(demand);
    default:
      return { verdictType: undefined };
  }
};

export const buildPmuVerdictInputCommand = (formValues: PmuVerdictInputFormValues): PmuVerdictInputCommand => {
  switch (formValues.verdictType) {
    case VerdictType.GRANTED:
      return toStandardGrantedVerdictInputCommand();
    case VerdictType.COMPLETE_CASE:
      return toStandardCompleteCaseVerdictInputCommand();
    case VerdictType.CANCELLED:
      return toStandardCancelledVerdictInputCommand(formValues);
    case VerdictType.REJECTED:
      return toStandardRejectedVerdictInputCommand(formValues);
    default:
      throw new Error('invalid verdict type for command creation');
  }
};

export const pmuActiveRejectionReasons: readonly RejectionReason[] = [
  RejectionReason.NO_BUDGET_JUSTIFICATION,
  RejectionReason.OTHER,
];
