import {
  CanDoDemandActionPostFilter,
  DemandActionType,
} from '@eogile/agrements-common/src/demand/components/actions/demandActionTypes';
import { currentUserAgency } from '../user/pmuUserUtils';
import { CurrentPmuApplicationUserDetails } from '../security/types';
import { PmuDemand } from './pmuDemandTypes';

export const pmuCanDoDemandActionPostFilter: CanDoDemandActionPostFilter = (actionType, ctx) => {
  const castedUserDetails = ctx.userDetails as CurrentPmuApplicationUserDetails;
  // only apply this filter for these actions :
  // demand update, attachements update, demand validation, ask cancellation, demand deletion
  if (
    Boolean(currentUserAgency(castedUserDetails)) &&
    [
      DemandActionType.EDIT,
      DemandActionType.UPDATE_ATTACHMENTS,
      DemandActionType.VALIDATE,
      DemandActionType.ASK_CANCELLATION,
      DemandActionType.DELETE,
      DemandActionType.ADD_COMMENT,
    ].includes(actionType)
  ) {
    return !Boolean(ctx.demand) || (ctx.demand as PmuDemand).agency.code === currentUserAgency(castedUserDetails)!.code;
  }
  return true;
};
