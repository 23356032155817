import { PmuDemand } from '../pmuDemandTypes';
import { Form } from 'react-final-form';
import { useMemo } from 'react';
import { PmuDemandFormValues } from '../pmuDemandFormTypes';
import { DemandFormProps } from '@eogile/agrements-common/src/demand/components/demandComponentTypes';
import { buildInitialPmuDemandFormValues, buildPmuDemandFormCommand } from '../pmuDemandFormUtils';
import { PmuDemandGeneralInformationSection } from './PmuDemandGeneralInformationSection';
import { PmuDemandOutletSection } from './PmuDemandOutletSection';
import { DemandFormTitle } from '@eogile/agrements-common/src/demand/components/DemandFormTitle';
import { FormMode, FormModeProvider } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import {
  isLightDemandForm,
  isVisibleMinistryDataSection,
  isVisibleMinistryVerdictSection,
} from '@eogile/agrements-common/src/demand/demandFormUtils';
import arrayMutators from 'final-form-arrays';
import { PmuDemandCandidatesArray } from './PmuDemandCandidatesArray';
import { DemandMinistryDataSection } from '@eogile/agrements-common/src/demand/components/DemandMinistryDataSection';
import { DemandMinistryVerdictSection } from '@eogile/agrements-common/src/demand/components/DemandMinistryVerdictSection';
import { DemandGeneralCommentSection } from '@eogile/agrements-common/src/demand/components/DemandGeneralCommentSection';
import { DemandAttachmentsSection } from '@eogile/agrements-common/src/demand/components/DemandAttachmentsSection';
import { DemandAcquirerSection } from '@eogile/agrements-common/src/demand/components/DemandAcquirerSection';
import { usePmuAuthenticatedCurrentUser } from '../../security/hooks';
import { PmuDemandFormOnChanges } from './PmuDemandFormOnChanges';
import { useDemandFormSubmitHelper } from '@eogile/agrements-common/src/demand/hooks/useDemandFormSubmitHelper';
import { DemandActions } from '@eogile/agrements-common/src/demand/components/actions/DemandActions';
import { ExistingAttachmentsRemovalWarningFieldsProvider } from '@eogile/agrements-common/src/demand/components/ExistingAttachmentsRemovalWarningFieldsProvider';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import PmuDemandCommentsSection from './comments/section/PmuDemandCommentsSection';

export const PmuDemandForm = (props: DemandFormProps<PmuDemand>) => {
  const { demand, mode, onSubmitSuccess, onDemandRefresh } = props;
  const submitForm = useDemandFormSubmitHelper<PmuDemand>(mode, demand?.id);
  const user = usePmuAuthenticatedCurrentUser();
  const initialValues = useMemo<PmuDemandFormValues>(
    () => buildInitialPmuDemandFormValues(demand, user.userDetails),
    [demand, user],
  );

  const onSubmit = async (values: PmuDemandFormValues) => {
    return await submitForm(buildPmuDemandFormCommand(values), onSubmitSuccess!);
  };

  const displayCommentsSection =
    mode === FormMode.READ && ![DemandStatus.CREATED, DemandStatus.VALIDATED].includes(demand.status);

  return (
    <FormModeProvider mode={mode}>
      <Form<PmuDemandFormValues> initialValues={initialValues} onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ExistingAttachmentsRemovalWarningFieldsProvider demand={demand}>
              <DemandFormTitle demand={demand} />
              <PmuDemandFormOnChanges />
              <PmuDemandGeneralInformationSection demand={demand} userDetails={user.userDetails} />
              <PmuDemandOutletSection />
              {!isLightDemandForm(values) && <DemandAcquirerSection supportsEirlFieldForNewDemands />}
              <PmuDemandCandidatesArray demand={demand} />
              {isVisibleMinistryDataSection(demand) && <DemandMinistryDataSection demand={demand} />}
              {isVisibleMinistryVerdictSection(demand, user) && (
                <DemandMinistryVerdictSection verdict={demand.verdict} variant={'NO_CANCELLATION_REASON'} />
              )}
              {mode === FormMode.READ && <DemandAttachmentsSection demand={demand} />}
              <DemandGeneralCommentSection />
              {displayCommentsSection && (
                <PmuDemandCommentsSection
                  demand={demand!}
                  currentUserDetails={user.userDetails}
                  onDemandRefresh={onDemandRefresh}
                />
              )}
              <DemandActions demand={demand} onDemandRefresh={onDemandRefresh} />
            </ExistingAttachmentsRemovalWarningFieldsProvider>
          </form>
        )}
      </Form>
    </FormModeProvider>
  );
};
