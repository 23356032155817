import { Card, CardContent, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import TablePagination from '@eogile/agrements-common/src/components/table/TablePagination';
import PmuDemandsTableHeader from './PmuDemandsTableHeader';
import { PmuDemandSearchCommand, PmuDemandsSortableFields, PmuSimpleDemandView } from '../pmuDemandListTypes';
import { demandStatusLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import { formatLocalDateForDisplay } from '@eogile/agrements-common/src/utils/dateUtils';
import dayjs from 'dayjs';
import { DemandListTableHeader } from '@eogile/agrements-common/src/demand/components/DemandListTableHeader';
import { DemandTableRow } from '@eogile/agrements-common/src/components/table/DemandTableRow';
import DemandCommentsCell from '@eogile/agrements-common/src/demand/table/internal/DemandCommentsCell';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';

type DemandsTableProps = {
  apiParams: PmuDemandSearchCommand;
  data: PaginatedListResponse<PmuSimpleDemandView>;
  onCommandChange: (newCommand: PaginationAndSortCommand<PmuDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<PmuDemandsSortableFields>;
};

const PmuDemandsTableCard = ({ apiParams, data, command, onCommandChange }: DemandsTableProps) => {
  const { numberOfResults, results } = data;
  const { userDetails } = useAuthenticatedCurrentUser();

  if (numberOfResults === 0) {
    return (
      <Card data-testid="table-no-result">
        <DemandListTableHeader numberOfResults={numberOfResults} apiParams={apiParams} />
      </Card>
    );
  }

  return (
    <Card>
      <DemandListTableHeader numberOfResults={numberOfResults} apiParams={apiParams} />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <PmuDemandsTableHeader command={command} onCommandChange={onCommandChange} />
            <TableBody>
              {results &&
                results.map((demandDetailsView) => (
                  <DemandTableRow key={demandDetailsView.id} demand={demandDetailsView}>
                    <TableCell>{demandDetailsView.chronoNumber}</TableCell>
                    <TableCell breakWord>{demandDetailsView.outletName}</TableCell>
                    <TableCell breakWord>{demandDetailsView.outletCity}</TableCell>
                    <TableCell>{demandDetailsView.agencyRegionalDirectionName}</TableCell>
                    <TableCell>{demandDetailsView.agencyName}</TableCell>
                    <TableCell>
                      {demandDetailsView.validationDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.validationDate))
                        : ''}
                    </TableCell>
                    <TableCell>
                      {demandDetailsView.receptionDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.receptionDate))
                        : ''}
                    </TableCell>
                    <TableCell>{demandStatusLabels[demandDetailsView.status]}</TableCell>
                    <TableCell>
                      {demandDetailsView.verdictDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.verdictDate))
                        : ''}
                    </TableCell>
                    <DemandCommentsCell currentUserDetails={userDetails} demandView={demandDetailsView} />
                  </DemandTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination<PmuDemandsSortableFields>
          numberOfResults={numberOfResults}
          onCommandChange={onCommandChange}
          command={command}
        />
      </CardContent>
    </Card>
  );
};

export default PmuDemandsTableCard;
