import React from 'react';
import useMarkAsRead from './internal/useMarkAsRead';
import { UserCompanyRestrictionToMarkCommentsAsReadFn } from './internal/commentsHookType';
import { CurrentUserDetails } from '../../../../security/types';
import { Comment, Demand } from '../../../demandTypes';

type ReturnType = {
  comments: readonly Comment[];
  ref: React.MutableRefObject<any>;
};

function useComments<D extends Demand>(
  demand: D,
  currentUserDetails: CurrentUserDetails,
  onDemandRefresh: (demand: D) => void,
  checkCompanyUserRestriction: UserCompanyRestrictionToMarkCommentsAsReadFn,
): ReturnType {
  return {
    comments: demand.comments,
    ref: useMarkAsRead(demand, currentUserDetails, onDemandRefresh, checkCompanyUserRestriction),
  };
}

export default useComments;
