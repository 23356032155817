import { useCallback, useEffect, useRef, useState } from 'react';
import { shouldMarkCommentsAsRead } from './useCommentsUtils';

import { UserCompanyRestrictionToMarkCommentsAsReadFn } from './commentsHookType';
import { Comment, Demand } from '../../../../demandTypes';
import { CurrentUserDetails } from '../../../../../security/types';
import ApiHelper from '../../../../../api/apiHelper';

/**
 * Hook in charge of marking the comments as read when the comments
 * section becomes visible.
 *
 * @param demand The current demand.
 * @param currentUserDetails The current user.
 * @param onDemandRefresh The function to call when comments have marked as read
 * in order to refresh the UI.
 * @param checkCompanyUserRestriction The function that checks restrictions for "company" users
 */
function useMarkAsRead<D extends Demand>(
  demand: D,
  currentUserDetails: CurrentUserDetails,
  onDemandRefresh: (demand: D) => void,
  checkCompanyUserRestriction: UserCompanyRestrictionToMarkCommentsAsReadFn,
) {
  const ref = useRef(null);
  const [visibleOnce, setVisibleOnce] = useState(false);

  /**
   * When the "comments" section becomes visible for the first time, it toggles the
   * flag that will trigger the HTTP request.
   */
  const onVisibilityChange = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!visibleOnce && entries[0].isIntersecting) {
        setVisibleOnce(true);
      }
    },
    [visibleOnce],
  );

  /**
   * Set up the intersection observer.
   */
  useEffect(() => {
    const options = { threshold: 0.8 };
    const observer = new IntersectionObserver(onVisibilityChange, options);
    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, onVisibilityChange]);

  /**
   * When the comments section has been visible, we mark the comments as read
   * if possible.
   */
  useEffect(() => {
    if (visibleOnce && shouldMarkCommentsAsRead(demand, currentUserDetails, checkCompanyUserRestriction)) {
      doMarkCommentsAsRead(demand).then((comments) =>
        onDemandRefresh({
          ...demand,
          comments,
        }),
      );
    }
  }, [visibleOnce, demand, currentUserDetails, onDemandRefresh, checkCompanyUserRestriction]);

  return ref;
}

async function doMarkCommentsAsRead(demand: Demand): Promise<readonly Comment[]> {
  return await ApiHelper.postJson(`/api/demands/${demand.id}/mark-comments-as-read`, {});
}

export default useMarkAsRead;
