import { CurrentPmuUserDetails } from '../../../../security/types';
import { PmuDemand } from '../../../pmuDemandTypes';
import { UserCompanyRestrictionToMarkCommentsAsReadFn } from '@eogile/agrements-common/src/demand/components/comments/hooks/internal/commentsHookType';

/**
 * Only "agency" users belonging to the same agency can mark comments as read.
 */
export const checkCompanyUserRestrictionToMarkAsRead: UserCompanyRestrictionToMarkCommentsAsReadFn = (
  userDetails,
  demand,
) => {
  const pmuDemand = demand as PmuDemand;
  const pmuUserDetails = userDetails as CurrentPmuUserDetails;
  return pmuDemand.agency.code === pmuUserDetails.agency?.code;
};
